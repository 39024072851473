import resolveMode from "helpers/resolveMode";

export default function resolveScopes (scopes) {
    let scopeList = scopes.split(" ");

    scopeList = getAdditionalScopes(scopeList);

    return scopeList.join(" ");
}

function getAdditionalScopes (scopeList) {
    let mode = resolveMode();

    scopeList.push(`mode:${mode}`);

    return scopeList;
}