import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";
import { Outlet } from "react-router-dom";

const ProductOverviewPage = lazy(() => import("pages/ProductOverviewPage"));
const ProductDetailPage = lazy(() => import("pages/ProductDetailPage"));
const EanToProductPage = lazy(() => import("pages/EanToProductPage"));
const Step1Page = lazy(() => import("pages/Step1Page"));
const Step2Page = lazy(() => import("pages/Step2Page"));
const Step3Page = lazy(() => import("pages/Step3Page"));
const Step4Page = lazy(() => import("pages/Step4Page"));
const Step5Page = lazy(() => import("pages/Step5Page"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "productOverview",
                index: true,
                inMenu: true,
                matchEnd: true,
                path: "/",
                title: "navigation.collection",
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductOverviewPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "productDetail",
                path: "/product",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductDetailPage />
                    </Suspense>
                ),
                children: []
            },        
            {
                key: "eanBarcodeToProduct",
                path: "/ean/:barcode",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <EanToProductPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCart",
                path: "cart",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step1Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCustomers",
                path: "customers",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step2Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutDetails",
                path: "details",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step3Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutOrder",
                path: "order",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step4Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutOrderCompleted",
                path: "order-completed",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step5Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "profile",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
        ],
        ...sharedRoutes
    },
];
