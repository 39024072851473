import { storage }  from "@divide/ois-react-framework/common";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";

export function pricesUrl () {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let url = `${appConfig.apis.ois.core.v1}Prices`;

    if (webshopCode) {
        url += `/ByWebshopCode/${webshopCode}`;
    }

    return url;
}