import appConfig from "constants/appConfig";

export function catalogVariantByBarcodeUrl (barcode = "") {
    let url = `${appConfig.apis.ois.core.v1}Catalog/VariantByBarcode/`;

    if (barcode) {
        url += `?Barcode=${barcode}`;
    }
    
    return url;
}