import { cookie } from "@divide/ois-react-framework/common";
import { getRetailSuiteBaseDomain } from "@divide/retailsuite-react-framework/helpers";
import storageKeys from "constants/storageKeys";

export const modeMap = ["default", "kiosk"];

export default function resolveMode () {
    let storedMode = cookie.get(storageKeys.mode);

    if (storedMode) {
        if (modeMap.includes(storedMode)) {
            return storedMode;
        } else {
            console.warn(`Current mode "${storedMode}" in cookies is unkown.`);
        }
    } else {
        setModeToCookies("default");
        return "default";
    }
}

export function setModeToCookies (name) {
    let domain = getRetailSuiteBaseDomain();
    cookie.remove(storageKeys.mode);
    cookie.set(storageKeys.mode, name, 30, domain, "/"); 
}