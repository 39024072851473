import { createContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslations, useResolveRoutesByAuthentication } from "@divide/ois-react-hooks";
import { useAuth0 } from "@divide/ois-react-components";
import { useCultureCode,useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess, useGetUserBranch,useIntervalReset } from "@divide/retailsuite-react-hooks";
import { Loader } from "@divide/retailsuite-react-components";
import { privateRoutes, publicRoutes } from "constants/routes"
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import useGetAllData from "hooks/general/useGetAllData";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import useSetWebshopCodeToStorage from "hooks/general/useSetWebshopCodeToStorage";
import useBranchWarning from "hooks/general/useBranchWarning";
import useGetStoredCartAmount from "hooks/general/useGetStoredCartAmount";
import useProductBarcodeScan from "hooks/general/useProductBarcodeScan";
import useSetShowOnStockProductsToStorage from "hooks/general/useSetShowOnStockProductsToStorage";
import useCustomerStore from "hooks/store/useCustomerStore";
import useConditionsStore from "hooks/store/useConditionsStore";
import "./App.scss";

export const AppContext = createContext();

export function App() {
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const { loading, isAuthenticated } = useAuth0();
    const { routes, element } = useResolveRoutesByAuthentication(useAuth0, useRoutes, privateRoutes, publicRoutes);

    const webshopSettings = useGetSettingsGroup(useAuth0, appConfig, "Webshop");
    const productDisplaySettings = useGetSettingsGroup(useAuth0, appConfig, "ProductDisplay");
    const generalConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "General");
    const dsaConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "DSA");
    const userBranch = useGetUserBranch(useAuth0, appConfig);

    useSetCdnDomainToStorage(webshopSettings.CdnDomain);
    useSetWebshopCodeToStorage(userBranch, dsaConfiguration.DefaultWebshopCodeDsa);
    useSetShowOnStockProductsToStorage(dsaConfiguration.ShowProductsWithoutStock);
    useIntervalReset(appConfig, storageKeys, dsaConfiguration.DataRefreshIntervalInMinutes);
    useBranchWarning(userBranch, translation);

    const { dataFetched, loadingText } = useGetAllData(translation, dsaConfiguration);
    const handleProductBarcodeScanResult = useProductBarcodeScan(translation, dsaConfiguration);
    const userAccess = useGetUserHasAccess(useAuth0, 1);

    const amount = useGetStoredCartAmount();
    const [cartAmount, setCartAmount] = useState(0);

    const [appLoading, setAppLoading] = useState(false);
    const [catalogSearchTerm, setCatalogSearchTerm] = useState();
    const [catalogSearchActive, setCatalogSearchActive] = useState(false);
    const [catalogBarcodeResponse, setCatalogBarcodeResponse] = useState();
    const [cachedCatalog, setCachedCatalog] = useState();
    const [cachedCatalogMaps, setCachedCatalogMaps] = useState();
    const [currentSort, setCurrentSort] = useState();
    const [priceByProductIds, setPriceByProductIds] = useState();
    
    useCustomerStore();
    useConditionsStore();
    
    useEffect(() => {
        setCartAmount(amount);
    }, [amount]);
    
    return loading ? (
        <Loader fullscreen={true} text={translation("messages.loading.default")} />
    ) : (
        <AppContext.Provider value={{ 
            webshopSettings,
            productDisplaySettings,
            generalConfiguration,
            dsaConfiguration,
            cultureCode, 
            setCultureCode, 
            translation,
            userAccess,
            userBranch,
            handleProductBarcodeScanResult,
            appLoading, 
            setAppLoading,
            catalogSearchTerm, 
            setCatalogSearchTerm,
            catalogSearchActive, 
            setCatalogSearchActive,
            catalogBarcodeResponse, 
            setCatalogBarcodeResponse,
            cachedCatalog, 
            setCachedCatalog,
            cachedCatalogMaps, 
            setCachedCatalogMaps,
            currentSort, 
            setCurrentSort,
            priceByProductIds, 
            setPriceByProductIds,
            cartAmount, 
            setCartAmount,
            routes
        }}>
            {(!dataFetched && isAuthenticated) ? (
                <Loader fullscreen={true} text={loadingText} />  
            ) : (
                <>
                    {appLoading && (
                        <Loader fullscreen={true} alpha={3} />
                    )}
                    
                    {element}
                </>
            )}
        </AppContext.Provider>
    );
}
