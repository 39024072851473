import appConfig from "constants/appConfig";

export function shelfStockUrl (articleNumber) {
    let url = `${appConfig.apis.ois.inventory.v1}shelves`;

    if (articleNumber) {
        url += `/${articleNumber}`;
    }

    return url;
}