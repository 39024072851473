import { useEffect } from 'react';
import { toast } from "react-toastify";

export default function useBranchWarning(userBranch, translation) {
    useEffect(() => {
        let toastText;

        if (userBranch?.status === "HAS_NO_BRANCH") {
            toastText = translation("messages.warning.noBranchMatch");
        } else if (userBranch?.status === "ERROR") {
            toastText = translation("messages.warning.branchFetchFailedNoMatch");
        }

        if (toastText) {
            toast.warn(toastText, { 
                position: "top-left",
                pauseOnFocusLoss: false
            });
        }
    }, [userBranch]);
}