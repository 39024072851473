import { useEffect, useState } from "react";
import { useAuth0 } from "@divide/ois-react-components";
import { hasProps } from "@divide/ois-react-framework/utils";
import { httpClient } from "@divide/ois-react-framework/common";
import { catalogUrl } from "helpers/endpointUrls";
import appConfig from "constants/appConfig";

function useGetProductBarcodeScanResult(triggered, scanResult, translation, dsaConfiguration) {
    const [productBarcodeScanResponse, setProductBarcodeScanResponse] = useState();
    const { user, getTokenSilently } = useAuth0();

    useEffect(() => {
        (async () => {
            if (!user || !triggered || !hasProps(dsaConfiguration)) return;
            if (!triggered) return;

            let bearerToken = await getTokenSilently();
            let { content: catalog } = await httpClient.get(catalogUrl(dsaConfiguration.ShowProductsWithoutStock), {}, bearerToken, true, appConfig);

            let productBarcodeScan = {
                valid: false,
                message: translation("messages.warning.noProductFoundWithBarcode", scanResult)
            };

            let foundVariant;

            for (let product of catalog) {
                if (foundVariant) break;

                for (let variant of product.Variants) {
                    if (variant.BarCode === scanResult || variant.EAN === scanResult) {;
                        foundVariant = variant;
                        break;
                    }
                }
            }

            if (foundVariant) {
                productBarcodeScan.valid = true;
                productBarcodeScan.productId = foundVariant.ProductId;
                productBarcodeScan.variantId = foundVariant.Id;
                productBarcodeScan.sizeId = foundVariant.Size;
                productBarcodeScan.colorId = foundVariant.Color;
                productBarcodeScan.message = "";
            }

            setProductBarcodeScanResponse(productBarcodeScan);
        })();
    }, [getTokenSilently, user, triggered, scanResult, dsaConfiguration]);

    return productBarcodeScanResponse;
}
export default useGetProductBarcodeScanResult;
