import nlNl from "./nlNl";
import deDe from "./deDe";
import enGb from "./enGb";
import frFr from "./frFr";

const translations = {
    "nl-NL": nlNl,
    "de-DE": deDe,
    "en-GB": enGb,
    "fr-FR": frFr
};

export default translations;