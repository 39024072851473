export function validateNoFutureDate(date) {
    if (!date) return true;

    if (typeof date === "string") {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;

        if (!datePattern.test(date)) {
            return false;
        }

        const [year, month, day] = date.split("-").map(Number);

        date = new Date(year, month - 1, day);

        if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
            return false;
        }
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date > today) {
        return false;
    }

    return true;
}
