const frFr = {
    global: {
        appName: "Digital Sales Assistant",
        articleGroups: "Groupes d'articles",
        articleNumber: "Numéro de l'article",
        fabricComposition: "Composition du tissu",
        typeToSearch: "Tapez pour rechercher...",
        currentCustomer: "Client actuel",
        deselectCustomer: "Désélectionner le client",
        collection: "Le recueil",
        searchTermResults: (searchterm) => `Résultats de la recherche "${searchterm}"`,
        articlesAmount: (amount) => `${amount} article(s)`,
        showArticlesAmount: (amount) => `${amount} afficher le(s) article(s)`,
        filters: "Filtres",
        categories: "Catégories",
        color: "Couleur",
        colors: "Couleurs",
        size: "Taille",
        sizes: "Tailles",
        brand: "Marque",
        brands: "Marques",
        gender: "Genre",
        search: "Chercher",
        price: "Prix",
        name: "Nom",
        name2: "Nom 2",
        noFiltersFound: (filterName) => `Aucun ${filterName} trouvé.`,
        moreFilters: (filterName) => `Plus de ${filterName}`,
        lessFilters: (filterName) => `Moins ${filterName}`,
        inStock: "En stock",
        selectedFilters: "Filtre(s) sélectionné(s)",
        deleteAllFilters: "Tout effacer",
        sortOptionName: "Nom",
        sortOptionPriceAsc: "Prix croissant",
        sortOptionPriceDesc: "prix décroissant",
        amount: "Nombre de",
        day: "Jour",
        time: "Temps",
        reason: "Rodé",
        noProductFound: "Aucun produit trouvé",
        backToOverview: "Retour à l'aperçu",
        back: "Retour",
        goBack: "retourner",
        gallery: "Galerie",
        productInformation: "Information produit",
        stockInformation: "Informations boursières",
        shelfInformation: "informations sur les étagères",
        expectedInventoryInformation: "Inventaire prévu",
        noStockInfoFound: "Aucune information boursière n'a été trouvée.",
        noSizesFound: "Aucune taille n'a été trouvée.",
        noStoresFound: "Aucun magasin trouvé",
        noPickupPointsFoundWithZipcode: (name) => `Aucun point de collecte ${name} n'a été trouvé avec le code postal spécifié.`,
        noStockFound: "Aucun stock n'a été trouvé.",
        totalStock: "Stock total",
        maxToOrder: "Max. à commander",
        moreBranches: "Plus de succursales",
        lessBranches: "Moins de succursales",
        addToCart: "Ajouter au panier",
        kioskMode: "Mode kiosque",
        on: "Sur",
        viewStockInformation: "Afficher les informations sur les actions",
        viewShelfInformation: "Afficher les informations sur l'étagère",
        none: "Rien",
        isSellableWithoutStockClarification: "Remarque: Cet article n'a pas de stock. Lors de la commande, une commande client est créée.",
        isSellableWithoutStockWithStockClarification: (amountType, amount) =>
            `Remarque : Pour cet article il y a ${amount} en stock. Si vous commandez plus, une commande client sera créée.`,
        externalStock: "Stock externe",
        setHasFollowingProducts: "Cet ensemble se compose des produits suivants",
        confirm: "Confirmer",
        vendorArticleNumber: "Numéro d'article du fournisseur",
        requestedDeliveryDate: "Date de livraison demandée",
        requestedPickupDate: "Date de ramassage demandée",
        pickupDate: "Date de ramassage",
        deliveryDate: "Date de livraison",
        xItemsExpectedOnDate: (amount, date) => (amount === 1 ? `${amount} pièce le ${date}` : `${amount} pièces le ${date}`),
        noExpectedInventoryFound: "Aucun stock attendu n'a été trouvé.",
        information: "Information",
        inventory: "Inventaire",
        informationAboutArticle: "Informations sur l'article",
        moreInformation: "Plus d'information",
        upwardsOf: "à partir de",
        filterProperties: "Filtre...",
        barcode: "Code à barre",
        ean: "EAN",
        selectOne: "Choisir une option...",
        outOfStock: "Rupture de stock",
        searchInAllCategories: "Rechercher toutes les catégories",
        searchWithoutFilters: "Rechercher sans filtres"
    },
    checkout: {
        shoppingCart: "panier",
        customerSelection: "Sélection client",
        customerDetails: "Données client",
        orderOverview: "Aperçu de la commande",
        billingAddress: "Adresse de facturation",
        otherAddress: "Adresse différente",
        deliveryMethod: "Mode de livraison",
        homeDelivery: "Livraison à domicile",
        storeDelivery: "Retrait en magasin",
        postNlDelivery: "Retrait dans un point PostNL",
        dhlDelivery: "Retrait en point DHL",
        pickupPointDelivery: "Retrait en point de collecte",
        free: "Gratuite",
        mr: "M.",
        mrs: "Mme.",
        continue: "Plus loin",
        continueOrdering: "Continuer à commander",
        editTotalDiscount: "Modifier la remise du bon",
        applyTotalDiscount: "Appliquer la remise du coupon",
        removeTotalDiscount: "Retirer le coupon",
        editDiscount: "Modifier la réduction",
        applyDiscount: "Appliquer la réduction",
        removeDiscount: "Supprimer la réduction",
        subTotal: "Total",
        discount: "Réduction",
        chooseDiscountReason: "Choisissez la raison de la réduction",
        shippingCosts: "Frais d'expédition",
        total: "Total",
        currencyAmount: "Montant",
        percentage: "Pour cent",
        delete: "Retirer",
        cancel: "Annuler",
        save: "Sauvegarder",
        previous: "Précédent",
        next: "Suivante",
        toDetails: "Aux données",
        storeSafetyCode: "Code de sécurité de la boutique",
        login: "Connexion",
        customer: "Client",
        customerNumber: "Numéro client",
        customerNumberAbbr: "Client n°",
        firstName: "Prénom",
        infix: "Particule",
        lastName: "Nom de famille",
        postalCode: "Code postal",
        houseNumber: "Numéro de maison",
        houseNumberAbbr: "Numéro de maison.",
        address: "Adresse",
        emailAddress: "Adresse e-mail",
        emailAddressAbbr: "E-mail",
        action: "Action",
        select: "Sélectionner",
        chooseOtherCustomer: "Choisir un autre client",
        results: "Résultats",
        searchBy: "Rechercher",
        postalCodeHouseNumber: "Code postal + Numéro de maison",
        condition: "Condition",
        beginsWith: "Commence avec",
        endsWith: "Se termine par",
        equals: "Égal à",
        contains: "Contient",
        searchTerm: "Terme de recherche",
        searchCustomer: "Rechercher un client",
        createCustomer: "Créer un client",
        continueAsGuest: "Continuer sans carte client",
        or: "Ou",
        edit: "Modifier",
        deliveryAddress: "Adresse de livraison",
        theNetherlands: "Les Pays-Bas",
        belgium: "Belgique",
        germany: "Allemagne",
        country: "Pays",
        houseNumberExtension: "Ajout",
        street: "Rue",
        city: "Endroit",
        salutation: "Salutation",
        phonenumber: "Numéro de téléphone",
        birthdate: "Date de naissance",
        overrideShippingCosts: "Remplacer les frais d'expédition",
        noShippingCosts: "Livraison gratuite",
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        sunday: "Dimanche",
        searchLocation: "Recherche par lieu",
        moreStores: "Plus de magasins",
        lessStores: "Moins de magasins",
        morePickupLocations: "Plus de points de collecte",
        lessPickupLocations: "Moins de points de collecte",
        openingTimes: "Horaires d'ouvertures",
        weeklyOpeningTimes: "Horaires d'ouverture hebdomadaires",
        extraOpeningTimes: "Horaires d'ouverture supplémentaires",
        placeOrder: "Commander",
        thanks: "Merci!",
        orderWasReceived: "Votre commande a été reçue en bon état.",
        youCanContinueShopping: "Vous pouvez immédiatement continuer vos achats en cliquant sur le bouton ci-dessous.",
        continueShopping: "Continuer vos achats",
        printOrderText: "Pour imprimer la commande, cliquez sur le bouton ci-dessous.",
        printOrder: "Ordre d'impression",
        noPhoneNumber: "Pas de numéro de téléphone",
        paymentOptions: "Options de paiement"
    },
    navigation: {
        collection: "Le recueil"
    },
    messages: {
        error: {
            orderError: "Une erreur s'est produite lors de la passation de votre commande.",
            addToCartError: "Une erreur s'est produite, le produit n'a pas pu être ajouté au panier.",
            saveCustomerError: "Une erreur s'est produite, vos données n'ont pas pu être enregistrées.",
            somethingWentWrong: "Une erreur s'est produite, veuillez contacter votre administrateur système."
        },
        warning: {
            discountInvalid: "La remise est égale ou supérieure au montant total de tous les produits, ajustez la remise.",
            editDiscount: "Ajuster la remise.",
            maxAmountItems: (amount) => `Max. ${amount} pièces`,
            noStock: (name) => `Il n'y a plus de stock pour le produit ${name}, retirez ce produit de votre panier.`,
            oneInStock: (name) => `Il reste 1 en stock du produit ${name}, veuillez modifier la quantité de ce produit si vous souhaitez continuer.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `Il y a ${stockAmount} en stock du produit ${name}, vous avez choisi ${chosenAmount}, modifiez la quantité de ce produit si vous souhaitez continuer.`,
            noStockForSize: (name, size) => `Il n'y a plus de stock pour la taille ${size} du produit. ${name} supprimez ce produit de votre panier.`,
            notEnoughStockForSize: (name, size, stock) =>
                `Il n'y a pas assez de stock pour la taille ${size} du produit ${name} (encore ${stock} en stock), ajustez la quantité de ce produit et réessayez .`,
            noProductsInCartInStock: "Aucun des produits de votre panier n'est en stock.",
            orderWasNotPlaced: "La commande n'a pas été passée",
            postNlLocationsFetchFailed:
                "Remarque: les emplacements PostNL n'ont pas pu être récupérés, consultez la console du navigateur pour plus de détails.",
            postNlLocationsError: "Une erreur s'est produite lors de la récupération des emplacements PostNL.",
            sizeColorComboNotFound: "La combinaison couleur/taille sélectionnée est introuvable.",
            noAccess: "Pas d'accès.",
            noAccessInKioskMode: "Vous ne pouvez pas accéder à cette page en mode kiosque.",
            notFound: "Pas trouvé.",
            noProductFoundWithBarcode: (barcode) => `Aucun produit n'a été trouvé avec le code-barres ${barcode}.`,
            noBranchMatch: "Remarque: Vous n'êtes pas lié à un établissement, vous ne pouvez donc pas passer de commande.",
            branchFetchFailedNoMatch:
                "Remarque: une erreur s'est produite lors de la récupération des emplacements. Par conséquent, aucun lien vers un emplacement n'a été trouvé et vous ne pouvez pas passer de commande."
        },
        info: {
            noProductsInCart: "Vous n'avez aucun produit dans votre panier.",
            shippingCostsInfo: "Vos frais de port sont calculés lorsque vous choisissez un mode de livraison à l'étape 3 du panier.",
            amountUpdated: (name, changedAmount) => `Le montant de ${name} a été changé en ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) => `Le produit ${name} de couleur ${color} de taille ${size} a été supprimé de votre panier.`,
            productWithSizeRemoved: (name, size) => `Le produit ${name} de taille ${size} a été supprimé de votre panier.`,
            noResults: "Aucun résultat trouvé.",
            noResultsSearchTerm: (term) => `Aucun résultat trouvé pour le terme de recherche <strong><em>${term}</em></strong>`,
            amountCustomersFound: (amount) => `${amount} client(s) trouvé(s).`,
            customerResultsExceeded:
                "<b>Remarque:</b> Un maximum de 10 résultats seront affichés. Si le client recherché n'est pas répertorié, vous devez affiner la recherche.",
            fieldMarkedWith: "Champs marqués d'un",
            areRequired: "sont des champs obligatoires",
            branchDistanceCalculationFailed:
                "Remarque : Les distances des branches n'ont pas pu être calculées, consultez la console du navigateur pour plus de détails.",
            dataWillBeRefreshed: "Les données de l'application sont obsolètes et sont actualisées automatiquement, veuillez patienter.",
            couldNotFetchAddress: "L'adresse ne peut pas être remplie automatiquement. Veuillez le saisir manuellement.",
            noPaymentOptions: "Aucune option de paiement n'a été trouvée, votre commande sera envoyée à la caisse.",
            noProductsFound: "Aucun produit trouvé",
            noProductsFoundInCategoryWithSearchTermAndFilters: (categoryName, searchTerm) =>
                `Aucun produit n'a été trouvé dans la catégorie "<b>${categoryName}</b>" avec le terme de recherche "<b>${searchTerm}</b>" et le filtre sélectionné (s).`,
            noProductsFoundInCategoryWithSearchTerm: (categoryName, searchTerm) =>
                `Aucun produit n'a été trouvé dans la catégorie "<b>${categoryName}</b>" avec le terme de recherche "<b>${searchTerm}</b>".`,
            noProductsFoundWithSearchTermAndFilters: (searchTerm) =>
                `Aucun produit n'a été trouvé avec le terme de recherche "<b>${searchTerm}</b>" et le(s) filtre(s) sélectionné(s).`,
            noProductsFoundWithSearchTerm: (searchTerm) =>
                `Aucun produit n'a été trouvé pour le terme de recherche "<b>${searchTerm}</b>". Veuillez utiliser un terme de recherche différent.`,
            noProductsFoundChooseOption: "Choisissez l'une des options ci-dessous pour continuer:"
        },
        success: {
            orderWasPlaced: "Votre commande a été enregistrée.",
            customerWasSelected: (name) => `${name} a été sélectionné avec succès en tant que client.`,
            customerWasDeselected: (name) => `${name} client a été désélectionné avec succès.`,
            customerWasCreated: (name) => `${name} a été créé avec succès.`,
            customerWasUpdated: (name) => `${name} a été adapté avec succès`,
            hasBeenAddedToThe: "a été ajouté au",
            detailsWereSaved: "Vos données ont été enregistrées avec succès."
        },
        validation: {
            pincodeIncorrect: "Le code de sécurité du magasin est incorrect.",
            searchTermRequired: `Le champ "Terme de recherche" est un champ obligatoire, veuillez le remplir.`,
            postalCodeInvalid: "Code postal et numéro de maison non valides, veuillez saisir un code postal et un numéro de maison valides.",
            choosePickupLocation: "Vous devez sélectionner un lieu de prise en charge.",
            choosePickupStore: "Vous devez sélectionner un point de retrait.",
            addressNotFound: "Aucun nom de rue et/ou de lieu n'a été trouvé avec le code postal et le numéro de maison actuels.",
            noBranchOrderDisallowed: "Vous ne pouvez pas passer de commande car vous n'êtes pas lié à un établissement.",
            selectAColorPlease: (colorTypeName) => `Veuillez sélectionner une ${colorTypeName}.`,
            selectASizePlease: (sizeTypeName) => `Veuillez choisir une ${sizeTypeName}.`,
            selectASubSizePlease: (subSizeTypeName) => `Veuillez choisir une ${subSizeTypeName}.`,
            selectAnAmountPlease: "Veuillez en sélectionner quelques-uns.",
            notEnoughInStock: "Il n'y a pas assez de stock, ajustez les quantités.",
            firstNameCharAmount: "Le prénom peut comporter jusqu'à 30 caractères.",
            lastNameCharAmount: "Le nom de famille peut contenir un maximum de 30 caractères.",
            infixCharAmount: "Le préfixe peut contenir un maximum de 30 caractères.",
            invalidDate: "La date est invalide",
            invalidDateNoFuture: "La date ne peut pas être dans le futur.",
            phoneNumberCharAmount: "Le numéro de téléphone peut contenir jusqu'à 50 caractères.",
            emailAlreadyExists: "L'adresse e-mail saisie est déjà utilisée, veuillez saisir une autre adresse e-mail.",
            customerNumberAlreadyExists: "Le numéro de client que vous avez entré est déjà utilisé, veuillez entrer un autre numéro de client.",
            fieldRequired: "Ce champ est requis.",
            fieldValidEmail: "Ce champ doit contenir une adresse e-mail valide.",
            chooseSpecifcity: "La spécification d'une adresse de livraison est obligatoire.",
            productUnavailable: (name) => `${name} est actuellement indisponible, veuillez réessayer plus tard.`,
            productHasNoStock: (name) => `${name} est actuellement en rupture de stock, veuillez réessayer plus tard.`,
            houseNoCharAmount: "Le numéro de rue peut contenir au maximum 10 caractères.",
            houseNoExtCharAmount: "L'ajout du numéro de rue peut contenir au maximum 10 caractères."
        },
        loading: {
            default: "Charger...",
            branches: "Chargement des lieux...",
            products: "Les produits sont en cours de chargement...",
            properties: "Chargement des propriétés...",
            propertyTypes: "Chargement des types de propriétés...",
            categories: "Chargement des catégories...",
            inventory: "Les stocks sont en cours de chargement...",
            prices: "Chargement des prix...",
            productProperties: "Chargement des propriétés du produit...",
            inventoryLinking: "Les stocks sont liées...",
            settings: "Chargement des paramètres...",
            catalog: "Chargement du catalogue...",
            detailsAreBeingSaved: "Vos données sont stockées...",
            oneMomentPlease: "Un instant s'il vous plaît...",
            orderIsBeingPlaced: "Votre commande est en cours...",
            searchingProduct: "Rechercher un produit..."
        }
    }
};

export default frFr;
