import { useEffect, useState } from "react";
import { httpClient, storage } from "@divide/ois-react-framework/common";
import { useAuth0 } from "@divide/ois-react-components";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import getStockData from "helpers/getStockData";
import {
    settingsUrl,
    propertiesUrl,
    propertyTypesUrl,
    categoryUrl,
    pricesUrl,
    productPropertiesUrl,
    inventoryBranchTypeUrl,
    branchesUrl,
    catalogUrl
} from "helpers/endpointUrls";


function useGetAllData(translation, dsaConfiguration) {
    const [loadingText, setLoadingText] = useState(translation("messages.loading.default"));
    const [dataFetched, setDataFetched] = useState(false);
    const { user, getTokenSilently } = useAuth0();

    const showProductsWithoutStock = storage.get(storageKeys.showProductsWithoutStock);

    useEffect(() => {
        (async () => {
            if (!user) return;
            
            let bearerToken = await getTokenSilently();

            setLoadingText(translation("messages.loading.settings"));
            let { content: settings } = await httpClient.get(settingsUrl("ProductDisplay"), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.properties"));
            await httpClient.get(propertiesUrl(), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.propertyTypes"));
            await httpClient.get(propertyTypesUrl(), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.categories"));
            await httpClient.get(categoryUrl(), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.prices"));
            await httpClient.get(pricesUrl(), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.productProperties"));
            await httpClient.get(productPropertiesUrl(), {}, bearerToken, true, appConfig);

            setLoadingText(translation("messages.loading.branches"));
            await httpClient.get(branchesUrl(), {}, bearerToken, true, appConfig);

            if (showProductsWithoutStock === "true") {
                let onStockProductIds = storage.get(storageKeys.onStockProductIds);
                onStockProductIds = JSON.parse(onStockProductIds);

                if (!onStockProductIds || !onStockProductIds.length) {
                    setLoadingText(translation("messages.loading.inventory"));
                    let { content: inventory } = await httpClient.get(inventoryBranchTypeUrl(), {}, bearerToken, false, appConfig);
                    
                    setLoadingText(translation("messages.loading.inventoryLinking"));
                    await getStockData(bearerToken, inventory, settings);
                }
            }
           
            setLoadingText(translation("messages.loading.products"));
            await httpClient.get(catalogUrl(), {}, bearerToken, true, appConfig);

            setDataFetched(true);
        })();
    }, [getTokenSilently, user, dsaConfiguration]);

    return { dataFetched, loadingText };
}

export default useGetAllData;
