import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "dsa_date",
    version: "dsa_version",
    branchPincodeSet: "dsa_branch-pincode-set",
    cart: "dsa_cart",
    currentCategory: "dsa_current-category",
    currentFilters: "dsa_current-filters",
    currentSort: "dsa_current-sort",
    onStockCheck: "dsa_on-stock-check",
    onStockProductIds: "dsa_on-stock-product-ids",
    customer: "dsa_customer",
    customerNew: "dsa_customer-new",
    customerFetchMethod: "dsa_customer-fetch-method",
    selectedDeliveryMethod: "dsa_selected-delivery-method",
    selectedPickupBranch: "dsa_selected-pickup-branch",
    selectedPickupPoint: "dsa_selected-pickup-point",
    selectedDeliveryLocation: "dsa_selected-delivery-location",
    selectedAddressSpecificity: "dsa_selected-address-specificity",
    selectedRequestedDeliveryDate: "dsa_selected-requested-delivery-date",
    shippingCosts: "dsa_shipping-costs",
    overrideShippingCosts: "dsa_override-shipping-costs",
    discountProduct: "dsa_discount-product",
    checkDiscountValue: "dsa_check-discount-value",
    checkDiscountType: "dsa_check-discount-type",
    checkDiscountTypeSelect: "dsa_check-discount-type-select",
    checkDiscountTotal: "dsa_check-discount-total",
    nextPage: "dsa_next-page",
    customerDetailsType: "dsa_customer-details-type",
    webshopCode: "dsa_webshop-code",
    showProductsWithoutStock: "dsa_show-products-without-stock",
    cdnDomain: "dsa_cdn-domain",
    refreshTime: "dsa_refresh-time",
    customerDetailsMethod: "dsa_customer-details-method",
    paymentOptionId: "dsa_payment-option-id",
    addToHomeScreenMessageIsClosed: "dsa_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "dsa_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "dsa_has-used-home-screen-app",
    productOverviewPageNumber: "dsa_product-overview-page-number",
    updateAvailable: "dsa_update_available",
    ...sharedStorageKeys
};

export default storageKeys;
