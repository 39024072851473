import { object, string, date, number, boolean } from "yup";
import validatePostalCodeByCountryCode from "helpers/validatePostalCodeByCountryCode";
import { validateNoFutureDate } from "helpers/date";

function shippingAddressRequired(conditions) {
    return conditions.deliveryMethod === "homeDelivery" && conditions.shipppingAddressSpecificity === "uniqueAddress";
}

function validateByShippingAddressLiability(value, context, message = "") {
    let currentValues = context.from[context.from.length - 1].value;

    if (shippingAddressRequired(currentValues.conditions)) {
        return !value ? context.createError({ message }) : true;
    } else {
        return true;
    }
}

const customerDetailsRules = {
    birthDate: date("messages.validation.invalidDate").nullable().test("noFutureDate", "messages.validation.invalidDateNoFuture", validateNoFutureDate),
    customerNumber: string().nullable(),
    email: string().email("messages.validation.fieldValidEmail").required("messages.validation.fieldRequired"),
    gender: string().matches(/(1|2)/, "messages.validation.fieldRequired").required("messages.validation.fieldRequired"),
    id: number().nullable(),
    firstName: string().required("messages.validation.fieldRequired").max(30, "messages.validation.firstNameCharAmount"),
    infix: string().max(30, "messages.validation.infixCharAmount").nullable(),
    lastName: string().required("messages.validation.fieldRequired").max(30, "messages.validation.lastNameCharAmount"),
    phoneNumber: string().nullable(),
    billingAddress: object().shape({
        city: string().required("messages.validation.fieldRequired"),
        countryCode: string().matches(/(NL|BE|DE|GB|FR)/, "messages.validation.fieldRequired"),
        houseNo: string().max(10, "messages.validation.houseNoCharAmount").required("messages.validation.fieldRequired"),
        houseNoExt: string().max(10, "messages.validation.houseNoExtCharAmount").nullable(),
        street: string().required("messages.validation.fieldRequired"),
        zipCode: string()
            .test("zipCodeTest", "", (value, context) => {
                let valid = !!value;
                let message = !value ? "messages.validation.fieldRequired" : "";

                if (value) {
                    valid = validatePostalCodeByCountryCode(context.parent.countryCode, value);
                    message = !valid ? "messages.validation.postalCodeInvalid" : "";
                }

                return valid ? valid : context.createError({ message });
            })
            .required("messages.validation.fieldRequired")
    })
};

export const customerDetailsSchema = object().shape({ ...customerDetailsRules });

export const checkoutCustomerFormSchema = object().shape({
    ...customerDetailsRules,
    shippingAddress: object().shape({
        city: string().test("shippingAddressCityTest", "", (value, context) => {
            return validateByShippingAddressLiability(value, context, "messages.validation.fieldRequired");
        }),
        countryCode: string()
            .matches(/(NL|BE|DE|GB|FR)/)
            .test("shippingAddressCountryCodeTest", "", (value, context) => {
                return validateByShippingAddressLiability(value, context, "messages.validation.fieldRequired");
            }),
        houseNo: string()
            .max(10, "messages.validation.houseNoCharAmount")
            .test("shippingAddressHouseNoTest", "", (value, context) => {
                return validateByShippingAddressLiability(value, context, "messages.validation.fieldRequired");
            }),
        houseNoExt: string().max(10, "messages.validation.houseNoExtCharAmount").nullable(),
        street: string().test("shippingAddressStreetTest", "", (value, context) => {
            return validateByShippingAddressLiability(value, context, "messages.validation.fieldRequired");
        }),
        zipCode: string().test("shippingAddressZipCodeTest", "", (value, context) => {
            let currentValues = context.from[context.from.length - 1].value;

            if (shippingAddressRequired(currentValues.conditions)) {
                let valid = !!value;
                let message = !value ? "messages.validation.fieldRequired" : "";

                if (value) {
                    valid = validatePostalCodeByCountryCode(currentValues.shippingAddress.countryCode, value);
                    message = !valid ? "messages.validation.postalCodeInvalid" : "";
                }

                return valid ? valid : context.createError({ message });
            } else {
                return true;
            }
        })
    }),
    conditions: object().shape({
        deliveryMethod: string().matches(/(homeDelivery|pickupFromStore|pickupFromPostnl|pickupFromDhl)/, ""),
        overrideShippingCosts: boolean().nullable(),
        shippingCosts: number(),
        shipppingAddressSpecificity: string("messages.validation.chooseSpecifcity").when("conditions.deliveryMethod", {
            is: "homeDelivery",
            then: (schema) => schema.matches(/(sameAsBillingAddress|uniqueAddress)/, "messages.validation.chooseSpecifcity"),
            otherwise: (schema) => schema.nullable()
        }),
        pickupBranchId: string("messages.validation.choosePickupLocation").when("deliveryMethod", {
            is: "pickupFromStore",
            then: (schema) => schema.required("messages.validation.choosePickupStore"),
            otherwise: (schema) => schema.nullable()
        }),
        pickupPointCode: string("messages.validation.choosePickupLocation").when("deliveryMethod", {
            is: (deliveryMethod) => ["pickupFromPostnl", "pickupFromDhl"].includes(deliveryMethod),
            then: (schema) => schema.required("messages.validation.choosePickupLocation"),
            otherwise: (schema) => schema.nullable()
        }),
        requestedDeliveryDate: date("messages.validation.invalidDate").nullable()
    })
});

export const defaultCheckoutCustomerFormValues = {
    birthDate: "01-01-1970",
    customerNumber: "",
    email: "",
    firstName: "",
    gender: 1,
    id: null,
    infix: "",
    lastName: "",
    phoneNumber: "",
    billingAddress: {
        city: "",
        countryCode: "NL",
        houseNo: "",
        houseNoExt: "",
        street: "",
        zipCode: ""
    },
    shippingAddress: {
        city: "",
        countryCode: "NL",
        houseNo: "",
        houseNoExt: "",
        street: "",
        zipCode: ""
    },
    conditions: {
        deliveryMethod: "pickupFromStore",
        shipppingAddressSpecificity: "sameAsBillingAddress",
        overrideShippingCosts: false,
        shippingCosts: 0,
        pickupBranchId: "",
        pickupPointCode: "",
        requestedDeliveryDate: ""
    }
};
