import { storage }  from "@divide/ois-react-framework/common";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";

export function inventoryStockVariantsUrl(parameters) {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let url = `${appConfig.apis.ois.core.v1}inventory/Stock/Variants?${parameters}`;

    if (webshopCode) {
        url = `${appConfig.apis.ois.core.v1}inventory/Stock/Variants/${webshopCode}?${parameters}`;
    }
    
    return url;
}
