import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useLocalStorage } from "@divide/ois-react-hooks";
import conditionsAtom from "store/conditions";
import storageKeys from "constants/storageKeys";
import { defaultCheckoutCustomerFormValues } from "constants/schema/checkoutCustomerFormSchema";

export default function useConditionsStore() {
    const { conditions: defaultConditions } = defaultCheckoutCustomerFormValues;

    const [conditions, setConditions] = useRecoilState(conditionsAtom);

    const [storageDeliveryMethod, setStorageDeliveryMethod] = useLocalStorage(storageKeys.selectedDeliveryMethod, defaultConditions.deliveryMethod);
    const [storageShippingAddressSpecificity, setStorageShippingAddressSpecificity] = useLocalStorage(
        storageKeys.selectedAddressSpecificity,
        defaultConditions.shipppingAddressSpecificity
    );
    const [storageOverrideShippingCosts, setStorageOverrideShippingCosts] = useLocalStorage(
        storageKeys.overrideShippingCosts,
        defaultConditions.overrideShippingCosts
    );
    const [storageShippingCosts, setStorageShippingCosts] = useLocalStorage(storageKeys.shippingCosts, defaultConditions.shippingCosts);
    const [storagePickupBranchId, setStoragePickupBranchId] = useLocalStorage(storageKeys.selectedPickupBranch, defaultConditions.pickupBranchId);
    const [storagePickupPointCode, setStoragePickupPointCode] = useLocalStorage(storageKeys.selectedPickupPoint, defaultConditions.pickupPointCode);
    const [storageRequestedDeliveryDate, setRequestedDeliveryDate] = useLocalStorage(
        storageKeys.selectedRequestedDeliveryDate,
        defaultConditions.requestedDeliveryDate
    );

    useEffect(() => {
        setConditions((state) => ({ ...state, deliveryMethod: storageDeliveryMethod }));
    }, [storageDeliveryMethod]);

    useEffect(() => {
        setConditions((state) => ({ ...state, shipppingAddressSpecificity: storageShippingAddressSpecificity }));
    }, [storageShippingAddressSpecificity]);

    useEffect(() => {
        setConditions((state) => ({ ...state, overrideShippingCosts: storageOverrideShippingCosts }));
    }, [storageOverrideShippingCosts]);

    useEffect(() => {
        setConditions((state) => ({ ...state, shippingCosts: storageShippingCosts }));
    }, [storageShippingCosts]);

    useEffect(() => {
        setConditions((state) => ({ ...state, pickupBranchId: storagePickupBranchId }));
    }, [storagePickupBranchId]);

    useEffect(() => {
        setConditions((state) => ({ ...state, pickupPointCode: storagePickupPointCode }));
    }, [storagePickupPointCode]);

    useEffect(() => {
        setConditions((state) => ({ ...state, requestedDeliveryDate: storageRequestedDeliveryDate }));
    }, [storageRequestedDeliveryDate]);

    useEffect(() => {
        setStorageDeliveryMethod(conditions.deliveryMethod);
        setStorageShippingAddressSpecificity(conditions.shipppingAddressSpecificity);
        setStorageOverrideShippingCosts(conditions.overrideShippingCosts);
        setStorageShippingCosts(conditions.shippingCosts);
        setStoragePickupBranchId(conditions.pickupBranchId);
        setStoragePickupPointCode(conditions.pickupPointCode);
        setRequestedDeliveryDate(conditions.requestedDeliveryDate);
    }, [conditions]);
}
