// WARNING: "Function" class usage is needed for production because babel messes up normal function declaration for workers.
// NEVER publish the worker while the "Function" class usage is commented.

//function stockDataWorker() {
const stockDataWorker = new Function(`
    self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
        if(!e) return;

        let { 
            minimumStock,
            catalogIdsOnStockObjects
        } = e.data;
        
        let onStockProductIds = getOnStockProducts(minimumStock, catalogIdsOnStockObjects);
        
        postMessage(onStockProductIds);
    });

    function getOnStockProducts (minimumStock, catalogIdsOnStockObjects) {
        let onStockProductIds = [];
        
        catalogIdsOnStockObjects.forEach((stockObjects, catalogId) => {
            let hasStock = getHasStock(stockObjects, minimumStock);
            
            if (hasStock) {
                onStockProductIds.push(catalogId);
            }
        });

        return onStockProductIds;
    }

    function getHasStock (items, minimumStock) {
        let hasStock = false;

        for (let i = 0; i < items.length; i++) {
            let isSellable = items[i].Settings.IsSellable;
            let isWareHouseAndHasQuantity = (items[i].BranchType?.toLowerCase() === "warehouse" && items[i].Quantity > 0);
            let isOtherAndHasQuantityOverMinimum = (items[i].Quantity > 0 && items[i].Quantity >= minimumStock);

            if (isWareHouseAndHasQuantity || (isOtherAndHasQuantityOverMinimum && isSellable)) {
                hasStock = true;
                break; 
            }
        }

        return hasStock;
    }
`);
//}

export default stockDataWorker;