import { useEffect } from "react";
import { storage } from "@divide/ois-react-framework/common";
import { hasProps } from "@divide/ois-react-framework/utils";
import storageKeys from "constants/storageKeys";

export default function useSetWebshopCodeToStorage(userBranch, defaultWebshopCode) {
    useEffect(() => {
        if (!hasProps(userBranch) || !defaultWebshopCode) return;

        let webshopCode = userBranch?.branch?.WebshopCodeDSA ?? defaultWebshopCode;
        
        if (webshopCode) {
            storage.set(storageKeys.webshopCode, webshopCode);
        }
    }, [userBranch, defaultWebshopCode]);
}
