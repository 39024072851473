import postalCodeRegexOnCountryCodeMap from "constants/postalCodeRegexOnCountryCodeMap"

export default function validatePostalCodeByCountryCode (countryCode, postalCode, validateIfUnkownCountryCode = false) {
    let valid = validateIfUnkownCountryCode ? false : true;
    
    if (postalCodeRegexOnCountryCodeMap.has(countryCode)) {
        let regex = postalCodeRegexOnCountryCodeMap.get(countryCode);
        valid = regex.test(postalCode);
    }

    return valid;
}