import { storage }  from "@divide/ois-react-framework/common";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";

export function orderUrl () {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let url = `${appConfig.apis.ois.core.v1}Order`;

    if (webshopCode) {
        url += `/${webshopCode}`;
    }

    return url;
}