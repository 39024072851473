import { storage }  from "@divide/ois-react-framework/common";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";

export function inventoryBranchTypeUrl () {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let showProductsWithoutStock = storage.get(storageKeys.showProductsWithoutStock);
    let url = `${appConfig.apis.ois.core.v1}Inventory/BranchType`;

    if (webshopCode) {
        url = `${appConfig.apis.ois.core.v1}Inventory/${webshopCode}/BranchType`;
    }

    if (showProductsWithoutStock === "false") {
        url += "?StockRequired=true";
    }

    return url;
}