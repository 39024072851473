import { storage, httpClient, successfulStatus } from "@divide/ois-react-framework/common";
import catalogStockWorker from "helpers/workers/catalogStockWorker";
import WebWorkerInstance from "helpers/webWorkerInstance";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import { catalogUrl, productSetsUrl } from "helpers/endpointUrls";

const webWorker = new WebWorkerInstance(catalogStockWorker);

async function getStockData(bearerToken, inventory, settings) {
    let response = await resolveStockData(bearerToken, inventory, settings);

    return response;
}

async function resolveStockData(bearerToken, inventory, settings) {
    let catalogResponse = await httpClient.get(catalogUrl(), {}, bearerToken, true, appConfig);
    let productSetsResponse = await httpClient.get(productSetsUrl(), {}, bearerToken, true, appConfig);

    if (successfulStatus(catalogResponse.status) && successfulStatus(productSetsResponse.status)) {
        await setProductIdsOnStock(catalogResponse.content, productSetsResponse.content, inventory, settings);
    }

    return Promise.resolve();
}

async function setProductIdsOnStock(catalog, productSets, inventory, settings) {
    let minimumStock = 0;

    if (settings && settings.length > 0) {
        let minimumStockSetting = settings.find((x) => x.Name === "minimumStock").Value;
        minimumStock = !isNaN(Number(minimumStockSetting)) ? Number(minimumStockSetting) : minimumStock;
    }

    const catalogIdsOnStockObjects = getCatalogIdsOnStockObjectsMap(catalog, inventory, productSets);

    webWorker.postMessage({
        minimumStock,
        catalogIdsOnStockObjects
    });

    webWorker.addEventListener("message", (e) => {
        storage.set(storageKeys.onStockProductIds, JSON.stringify(e.data));
        webWorker.terminate();
    });

    return Promise.resolve();
}

function getCatalogIdsOnStockObjectsMap (catalog, inventory, productSets) {
    const catalogIdsOnStockObjects = new Map();
    const productIdToInventoriesMap = new Map();

    for (let inventoryItem of inventory) {
        let inventories = productIdToInventoriesMap.get(inventoryItem.ProductId) || [];

        inventories.push(inventoryItem);
        productIdToInventoriesMap.set(inventoryItem.ProductId, inventories);
    }

    for (let item of catalog) {
        if (!catalogIdsOnStockObjects.has(item.Id)) {
            const foundInventories = productIdToInventoriesMap.get(item.Id) || [];

            if (foundInventories.length) {
                catalogIdsOnStockObjects.set(item.Id, foundInventories);
            } else {
                const foundProductSet = productSets.find(productSet => {
                    return item.Variants.some(variant => variant.Id === productSet.VariantId);
                });

                if (foundProductSet) {
                    const foundProductSetItemProductIds = foundProductSet.ProductSetItems.map(productSetItem => productSetItem.ProductId);

                    const foundProductSetInventories = foundProductSetItemProductIds.reduce((inventories, id) => {
                        const inventory = productIdToInventoriesMap.get(id) || [];
                        return inventories.concat(inventory);
                    }, []);

                    catalogIdsOnStockObjects.set(item.Id, foundProductSetInventories);
                }
            }
        }
    }

    return catalogIdsOnStockObjects;
}

/*
function getCatalogIdsOnStockObjectsMapOld (catalog, inventory, productSets) {
    const catalogIdsOnStockObjects = new Map();

    catalog.forEach(item => {
        if (!catalogIdsOnStockObjects.has(item.Id)) {
            let foundInventories = inventory.filter(inventoryItem => inventoryItem.ProductId === item.Id);

            if (foundInventories?.length) {
                catalogIdsOnStockObjects.set(item.Id, foundInventories);
            } else {
                let foundProductSet = productSets.find(productSet => {
                    return item.Variants.some(variant => variant.Id === productSet.VariantId);
                });

                if (foundProductSet) {
                    let foundProductSetItemProductIds = foundProductSet.ProductSetItems.map(productSetItem => productSetItem.ProductId);

                    let foundProductSetInventories = inventory.filter(inventoryItem => {
                        return foundProductSetItemProductIds.some(id => id === inventoryItem.ProductId);
                    });

                    catalogIdsOnStockObjects.set(item.Id, foundProductSetInventories);
                }
            }
        }
    });

    return catalogIdsOnStockObjects;
}
*/

export default getStockData;