import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useLocalStorage } from "@divide/ois-react-hooks";
import customerAtom from "store/customer";
import storageKeys from "constants/storageKeys";

export default function useCustomerStore () {
    const [customer, setCustomer] = useRecoilState(customerAtom);
    const [storageCustomer, setStorageCustomer] = useLocalStorage(storageKeys.customer);

    useEffect(() => {
        let parsedCustomer = storageCustomer;

        if (storageCustomer) {
            parsedCustomer = (typeof storageCustomer === "string") ? JSON.parse(storageCustomer) : storageCustomer;
        }

        setCustomer(parsedCustomer);
    }, [storageCustomer]);

    useEffect(() => {
        setStorageCustomer(customer);
    }, [customer]);
}