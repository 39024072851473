const deDe = {
    global: {
        appName: "Digital Sales Assistant",
        articleGroups: "Artikelgruppen",
        articleNumber: "Artikelnummer",
        fabricComposition: "Stoffzusammensetzung",
        typeToSearch: "Tippe um zu suchen...",
        currentCustomer: "Aktuelle Kunden",
        deselectCustomer: "Kunde abwählen",
        collection: "Sammlung",
        searchTermResults: (searchterm) => `Ergebnisse Suche "${searchterm}"`,
        articlesAmount: (amount) => `${amount} Artikel(s)`,
        showArticlesAmount: (amount) => `${amount} Artikel anzeigen`,
        filters: "Filter",
        categories: "Kategorien",
        color: "Farbe",
        colors: "Farben",
        size: "Größe",
        sizes: "Größen",
        brand: "Marke",
        brands: "Marken",
        gender: "Geschlecht",
        search: "Suchen",
        price: "Preis",
        name: "Name",
        name2: "Name 2",
        noFiltersFound: (filterName) => `Kein ${filterName} gefunden.`,
        moreFilters: (filterName) => `Mehr ${filterName}`,
        lessFilters: (filterName) => `Weniger ${filterName}`,
        inStock: "Auf Lager",
        selectedFilters: "Ausgewählte(r) Filter",
        deleteAllFilters: "Alles löschen",
        sortOptionName: "Name",
        sortOptionPriceAsc: "Preis steigend",
        sortOptionPriceDesc: "Preis sinkend",
        amount: "Anzahl",
        day: "Tag",
        time: "Zeit",
        reason: "Ritt",
        noProductFound: "Kein Produkt gefunden",
        backToOverview: "Zurück zur Übersicht",
        back: "Zurück",
        goBack: "geh zurück",
        gallery: "Galerie",
        productInformation: "Produktinformation",
        stockInformation: "Lagerinformationen",
        shelfInformation: "Regalinformationen",
        expectedInventoryInformation: "Erwarteter Bestand",
        noStockInfoFound: "Es wurden keine Bestandsinformationen gefunden.",
        noSizesFound: "Es wurden keine Größen gefunden.",
        noStoresFound: "Keine Geschäfte gefunden",
        noPickupPointsFoundWithZipcode: (name) => `Mit der angegebenen Postleitzahl wurden keine ${name}-Sammelstellen gefunden.`,
        noStockFound: "Es wurde kein Bestand gefunden.",
        totalStock: "Gesamtbestand",
        maxToOrder: "Max. auf Bestellung",
        moreBranches: "Mehr Filialen",
        lessBranches: "Weniger Filialen",
        addToCart: "In den Warenkorb legen",
        kioskMode: "Kiosk modus",
        on: "An",
        viewStockInformation: "Lagerinformationen anzeigen",
        viewShelfInformation: "Regalinformationen anzeigen",
        none: "Keiner",
        isSellableWithoutStockClarification: "Hinweis: Dieser Artikel ist nicht auf Lager. Bei der Bestellung wird eine Nachbestellung erstellt.",
        isSellableWithoutStockWithStockClarification: (amountType, amount) =>
            `Hinweis: Für diesen artikel ${
                amountType === "plural" ? "sind noch da" : "ist noch da"
            } ${amount} auf Lager. Wenn Sie mehr bestellen, wird ein Nachbestellung erstellt.`,
        externalStock: "Externer Bestand",
        setHasFollowingProducts: "Dieses Set besteht aus den folgenden Produkten",
        confirm: "Bestätigen",
        vendorArticleNumber: "Artikelnummer des Anbieters",
        requestedDeliveryDate: "Date de livraison demandée",
        requestedPickupDate: "Date de ramassage demandée",
        pickupDate: "Date de ramassage",
        deliveryDate: "Date de livraison",
        xItemsExpectedOnDate: (amount, date) => `${amount} Stück am ${date}`,
        noExpectedInventoryFound: "Kein erwarteter Bestand gefunden.",
        information: "Information",
        inventory: "Inventar",
        informationAboutArticle: "Informationen zum Artikel",
        moreInformation: "Mehr Informationen",
        upwardsOf: "aus",
        filterProperties: "Filterfunktionen...",
        barcode: "Barcode",
        ean: "EAN",
        selectOne: "Wähle eine Option...",
        outOfStock: "Ausverkauft",
        searchInAllCategories: "Alle Kategorien durchsuchen",
        searchWithoutFilters: "Suche ohne Filter"
    },
    checkout: {
        shoppingCart: "Einkaufswagen",
        customerSelection: "Kundenauswahl",
        customerDetails: "Kundendaten",
        orderOverview: "Bestellübersicht",
        billingAddress: "Rechnungsadresse",
        otherAddress: "Andere Adresse",
        deliveryMethod: "Versandart",
        homeDelivery: "Hauslieferung",
        storeDelivery: "Abholung im Laden",
        postNlDelivery: "Abholung an einer PostNL-Stelle",
        dhlDelivery: "Abholung an einer DHL-Stelle",
        pickupPointDelivery: "Abholung an einer Abholstelle",
        free: "Frei",
        mr: "Herr.",
        mrs: "Frau.",
        continue: "Weiter",
        continueOrdering: "Weiter zur Bestellung",
        editTotalDiscount: "Gutscheinrabatt ändern",
        applyTotalDiscount: "Gutscheinrabatt anwenden",
        removeTotalDiscount: "Gutschein entfernen",
        editDiscount: "Rabatt ändern",
        applyDiscount: "Rabatt anwenden",
        removeDiscount: "Rabatt entfernen",
        subTotal: "Zwischensumme",
        discount: "Rabatt",
        chooseDiscountReason: "Rabattgrund wählen",
        shippingCosts: "Portokosten",
        total: "Gesamt",
        currencyAmount: "Menge",
        percentage: "Prozent",
        delete: "Entfernen",
        cancel: "Absagen",
        save: "Speichern",
        previous: "Zurück",
        next: "Nächster",
        toDetails: "Zu Daten",
        storeSafetyCode: "Shop-Sicherheitscode",
        login: "Anmeldung",
        customer: "Kunde",
        customerNumber: "Kundennummer",
        customerNumberAbbr: "Kundennr.",
        firstName: "Vorname",
        infix: "Präfix",
        lastName: "Nachname",
        postalCode: "Postleitzahl",
        houseNumber: "Hausnummer",
        houseNumberAbbr: "Straße Hausnr.",
        address: "Adresse",
        emailAddress: "E-Mail Addresse",
        emailAddressAbbr: "Email",
        action: "Aktion",
        select: "Auswählen",
        chooseOtherCustomer: "Anderen Kunden wählen",
        results: "Ergebnisse",
        searchBy: "Suchen nach",
        postalCodeHouseNumber: "Postleitzahl + Hausnummer",
        condition: "Bedingung",
        beginsWith: "Beginnt mit",
        endsWith: "Endet mit",
        equals: "Gleicht",
        contains: "Enthält",
        searchTerm: "Suchbegriff",
        searchCustomer: "Kunden finden",
        createCustomer: "Kunde anlegen",
        continueAsGuest: "Weiter ohne Kundenkarte",
        or: "Oder",
        edit: "Herstellen",
        deliveryAddress: "Lieferadresse",
        theNetherlands: "Die Niederlande",
        belgium: "Belgien",
        germany: "Deutschland",
        country: "Land",
        houseNumberExtension: "Zusatz",
        street: "Straße",
        city: "Ort",
        salutation: "Anrede",
        phonenumber: "Telefonnummer",
        birthdate: "Geburtsdatum",
        overrideShippingCosts: "Versandkosten außer Kraft setzen",
        noShippingCosts: "Kostenloser Versand",
        monday: "Montag",
        tuesday: "Dienstag",
        wednesday: "Mittwoch",
        thursday: "Donnerstag",
        friday: "Freitag",
        saturday: "Samstag",
        sunday: "Sonntag",
        searchLocation: "Suche nach Ort",
        moreStores: "Mehr Geschäfte",
        lessStores: "Weniger Geschäfte",
        morePickupLocations: "Weitere Sammelstellen",
        lessPickupLocations: "Weniger Sammelstellen",
        openingTimes: "Öffnungszeiten",
        weeklyOpeningTimes: "Wöchentliche Öffnungszeiten",
        extraOpeningTimes: "Zusätzliche Öffnungszeiten",
        placeOrder: "Eine Bestellung aufgeben",
        thanks: "Vielen Dank!",
        orderWasReceived: "Ihre Bestellung ist in gutem Zustand eingegangen.",
        youCanContinueShopping: "Sie können Ihren Einkauf sofort fortsetzen, indem Sie auf die Schaltfläche unten klicken.",
        continueShopping: "Mit dem Einkaufen fortfahren",
        printOrderText: "Um die Bestellung auszudrucken, klicken Sie auf die Schaltfläche unten.",
        printOrder: "Bestellung drucken",
        noPhoneNumber: "Keine Telefonnummer",
        paymentOptions: "Zahlungsmöglichkeiten"
    },
    navigation: {
        collection: "Sammlung"
    },
    messages: {
        error: {
            orderError: "Bei Ihrer Bestellung ist etwas schief gelaufen.",
            addToCartError: "Etwas ist schief gelaufen, das Produkt konnte nicht in den Warenkorb gelegt werden.",
            saveCustomerError: "Etwas ist schief gelaufen, Ihre Daten konnten nicht gespeichert werden.",
            somethingWentWrong: "Etwas ist schief gelaufen, bitte wenden Sie sich an Ihren Systemadministrator."
        },
        warning: {
            discountInvalid: "Rabatt entspricht oder übersteigt den Gesamtbetrag aller Produkte, passen Sie den Rabatt an.",
            editDiscount: "Rabatt anpassen.",
            maxAmountItems: (amount) => `Max. ${amount} Stück`,
            noStock: (name) => `Das Produkt ${name} ist nicht auf Lager, entfernen Sie dieses Produkt aus Ihrem Einkaufswagen.`,
            oneInStock: (name) =>
                `Es ist noch 1 Stück des Produkts ${name} auf Lager, bitte ändern Sie die Menge dieses Produkts, wenn Sie fortfahren möchten.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `Es sind ${stockAmount} des Produkts ${name} auf Lager, Sie haben ${chosenAmount} gewählt, ändern Sie die Menge dieses Produkts, wenn Sie fortfahren möchten.`,
            noStockForSize: (name, size) =>
                `Für die Größe ${size} des Produkts ist kein Lagerbestand vorhanden. ${name} dieses Produkt aus Ihrem Einkaufswagen entfernen.`,
            notEnoughStockForSize: (name, size, stock) =>
                `Es ist nicht genug Lagerbestand für die Größe ${size} des Produkts ${name} (noch ${stock} auf Lager), passen Sie die Menge dieses Produkts an und versuchen Sie es erneut .`,
            noProductsInCartInStock: "Keines der Produkte in Ihrem Warenkorb ist auf Lager.",
            orderWasNotPlaced: "Bestellung wurde nicht aufgegeben.",
            postNlLocationsFetchFailed: "Achtung: PostNL-Standorte konnten nicht abgerufen werden. Weitere Informationen finden Sie in der Browserkonsole.",
            postNlLocationsError: "Beim Abrufen der PostNL-Standorte ist ein Fehler aufgetreten.",
            sizeColorComboNotFound: "Die ausgewählte Farb-/Größenkombination konnte nicht gefunden werden.",
            noAccess: "Kein Zugang.",
            noAccessInKioskMode: "Sie können im Kioskmodus nicht auf diese Seite zugreifen.",
            notFound: "Nicht gefunden.",
            noProductFoundWithBarcode: (barcode) => `Es wurde kein Produkt mit Barcode ${barcode} gefunden.`,
            noBranchMatch: "Achtung: Sie sind mit keiner Einrichtung verbunden, daher können Sie keine Bestellungen aufgeben.",
            branchFetchFailedNoMatch:
                "Achtung: Beim Abrufen der Standorte ist ein Fehler aufgetreten. Daher wurde kein Link zu einem Standort gefunden und Sie können keine Bestellungen aufgeben."
        },
        info: {
            noProductsInCart: "Sie haben keine Produkte in Ihrem Warenkorb.",
            shippingCostsInfo: "Ihre Versandkosten werden berechnet, wenn Sie in Schritt 3 des Warenkorbs eine Versandart auswählen.",
            amountUpdated: (name, changedAmount) => `Der Betrag für ${name} wurde in ${changedAmount} geändert.`,
            productWithColorAndSizeRemoved: (name, color, size) =>
                `Das Produkt ${name} mit der Farbe ${color} in der Größe ${size} wurde aus Ihrem Einkaufswagen entfernt.`,
            productWithSizeRemoved: (name, size) => `Das Produkt ${name} in der Größe ${size} wurde aus Ihrem Einkaufswagen entfernt.`,
            noResults: "Keine Ergebnisse gefunden.",
            noResultsSearchTerm: (term) => `Für den Suchbegriff <strong><em>${term}</em></strong> wurden keine Ergebnisse gefunden.`,
            amountCustomersFound: (amount) => `${amount} Kunde(n) gefunden.`,
            customerResultsExceeded:
                "<b>Hinweis:</b> Es werden maximal 10 Ergebnisse angezeigt. Wenn der gewünschte Kunde nicht aufgeführt ist, müssen Sie die Suche verfeinern.",
            fieldMarkedWith: "Mit a gekennzeichnete Felder",
            areRequired: "sind Pflichtfelder",
            branchDistanceCalculationFailed: "Achtung: Die Entfernungen der Äste konnten nicht berechnet werden, siehe Browserkonsole für weitere Details.",
            dataWillBeRefreshed: "Die Daten der Anwendung sind veraltet und werden automatisch aktualisiert, bitte warten.",
            couldNotFetchAddress: "Die Adresse kann nicht automatisch ausgefüllt werden. Bitte geben Sie es manuell ein.",
            noPaymentOptions: "Es wurden keine Zahlungsoptionen gefunden, Ihre Bestellung wird zur Kasse geschickt.",
            noProductsFound: "Keine Produkte gefunden",
            noProductsFoundInCategoryWithSearchTermAndFilters: (categoryName, searchTerm) =>
                `Keine Produkte wurden in der Kategorie "<b>${categoryName}</b>" mit dem Suchbegriff "<b>${searchTerm}</b>" und dem ausgewählten Filter gefunden (s).`,
            noProductsFoundInCategoryWithSearchTerm: (categoryName, searchTerm) =>
                `Keine Produkte wurden in der Kategorie "<b>${categoryName}</b>" mit dem Suchbegriff "<b>${searchTerm}</b>" gefunden.`,
            noProductsFoundWithSearchTermAndFilters: (searchTerm) =>
                `Es wurden keine Produkte mit dem Suchbegriff "<b>${searchTerm}</b>" und den ausgewählten Filtern gefunden.`,
            noProductsFoundWithSearchTerm: (searchTerm) =>
                `Für den Suchbegriff "<b>${searchTerm}</b>" wurden keine Produkte gefunden. Bitte verwenden Sie einen anderen Suchbegriff.`,
            noProductsFoundChooseOption: "Wählen Sie eine der folgenden Optionen, um fortzufahren:"
        },
        success: {
            orderWasPlaced: "Deine Bestellung wurde aufgenommen.",
            customerWasSelected: (name) => `<b>${name}</b> wurde erfolgreich als Kunde ausgewählt.`,
            customerWasDeselected: (name) => `<b>${name}</b> wurde erfolgreich deselektiert.`,
            customerWasCreated: (name) => `<b>${name}</b> wurde erfolgreich erstellt.`,
            customerWasUpdated: (name) => `<b>${name}</b> wurde erfolgreich adaptiert.`,
            hasBeenAddedToThe: "wurde hinzugefügt zu",
            detailsWereSaved: "Ihre Daten wurden erfolgreich gespeichert."
        },
        validation: {
            pincodeIncorrect: "Der Sicherheitscode des Geschäfts ist falsch.",
            searchTermRequired: `Das Feld "Suchbegriff" ist ein Pflichtfeld, bitte ausfüllen.`,
            postalCodeInvalid: "Ungültige Postleitzahl und Hausnummer, bitte geben Sie eine gültige Postleitzahl und Hausnummer ein.",
            choosePickupLocation: "Sie müssen einen Abholort auswählen.",
            choosePickupStore: "Sie müssen ein Abholgeschäft auswählen.",
            addressNotFound: "Es wurde kein Straßen- und/oder Ortsname mit der aktuellen Postleitzahl und Hausnummer gefunden.",
            noBranchOrderDisallowed: "Sie können keine Bestellung aufgeben, da Sie mit keiner Einrichtung verbunden sind.",
            selectAColorPlease: (colorTypeName) => `Bitte wähle eine ${colorTypeName} aus.`,
            selectASizePlease: (sizeTypeName) => `Bitte wähle eine ${sizeTypeName} aus.`,
            selectASubSizePlease: (subSizeTypeName) => `Bitte wähle eine ${subSizeTypeName} aus.`,
            selectAnAmountPlease: "Bitte wählen Sie einige aus.",
            notEnoughInStock: "Es ist nicht genug auf Lager, passen Sie die Mengen an.",
            firstNameCharAmount: "Vorname kann bis zu 30 Zeichen lang sein.",
            lastNameCharAmount: "Der Nachname darf maximal 30 Zeichen enthalten.",
            infixCharAmount: "Das Präfix darf maximal 30 Zeichen enthalten.",
            invalidDate: "Das Datum ist ungültig.",
            invalidDateNoFuture: "Das Datum kann nicht in der Zukunft liegen.",
            phoneNumberCharAmount: "Die Telefonnummer kann bis zu 50 Zeichen enthalten.",
            emailAlreadyExists: "Die eingegebene E-Mail Adresse wird bereits verwendet, bitte geben Sie eine andere E-Mail Adresse ein.",
            customerNumberAlreadyExists: "Die eingegebene Kundennummer wird bereits verwendet, bitte geben Sie eine andere Kundennummer ein.",
            fieldRequired: "Dieses Feld wird benötigt.",
            fieldValidEmail: "Dieses Feld muss eine gültige E-Mail-Adresse enthalten.",
            chooseSpecifcity: "Die Angabe einer Lieferadresse ist obligatorisch.",
            productUnavailable: (name) => `${name} ist derzeit nicht verfügbar, bitte versuchen Sie es später erneut.`,
            productHasNoStock: (name) => `${name} ist derzeit nicht auf Lager, bitte versuchen Sie es später erneut.`,
            houseNoCharAmount: "Die Hausnummer darf maximal 10 Zeichen enthalten.",
            houseNoExtCharAmount: "Der Hausnummernzusatz darf maximal 10 Zeichen enthalten."
        },
        loading: {
            default: "Data wird geladen...",
            branches: "Lade Orte...",
            products: "Produkte werden geladen...",
            properties: "Eigenschaften werden geladen...",
            propertyTypes: "Eigenschaftstypen werden geladen...",
            categories: "Kategorien werden geladen...",
            inventory: "Lager werde geladen...",
            prices: "Preise werden geladen...",
            productProperties: "Produkteigenschaften werden geladen...",
            inventoryLinking: "Lager werde gekoppelt....",
            settings: "Lade Einstellungen...",
            catalog: "Katalog wird geladen...",
            detailsAreBeingSaved: "Ihre Daten werden gespeichert...",
            oneMomentPlease: "Einen Moment bitte...",
            orderIsBeingPlaced: "Ihre Bestellung wird aufgegeben...",
            searchingProduct: "Produkt suchen..."
        }
    }
};

export default deDe;
